import React, { useState, useEffect } from 'react';
import './App.css';

const App = () => {
  const [formData, setFormData] = useState({
    departureDate: '',
    departureTime: '',
    departureTimezone: '',
    arrivalDate: '',
    arrivalTime: '',
    arrivalTimezone: '',
    flightDuration: ''
  });
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [ are_updates_available, set_are_updates_available ] = useState(false);
  // const fullYear = new Date().getFullYear();

  useEffect(() => {
    
    const checkForUpdates = async () => {
        try
        {
            const response = await fetch(`${process.env.REACT_APP_MAIN_URL}/version`);

            if (response.ok)
            {
                const data = await response.json();
                const backendVersion = data.version;
                const frontendVersion = process.env.REACT_APP_VERSION;

                if (backendVersion !== frontendVersion)
                {
                  // window.location.reload(); // Refresh the page when an update is detected
                  set_are_updates_available(true);
                }
                else
                {
                  set_are_updates_available(false);
                }
            }
        }
        catch (error)
        {
            console.error("Error checking version:", error);
        }
    };

    // Run the check immediately on mount
    checkForUpdates();
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    
    // const data_timeDifference = "1 hour";
    // setMessage(`Time gained/lost: ${data_timeDifference}`);

    const default_message = "☉⌓☉ You were sent to 2000-late BC by mistake."
    + "  Please board Flight FW 200 at Gate North One (NONE) to return to the present time."
    + "  Thank you for your cooperation.";

    try {
      // const response = await fetch(`${process.env.REACT_APP_MAIN_URL}/api/calculate-time-travel`, {
      //   method: 'POST',
      //   headers: { 'Content-Type': 'application/json' },
      //   body: JSON.stringify(formData)
      // });
      
      await fetch(`${process.env.REACT_APP_MAIN_URL}/api/calculate-time-travel`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData)
      });

      // const data = await response.json();
      // setMessage(`Time gained/lost: ${data.timeDifference}`);
      setMessage(default_message);
    } catch (error) {
      // setMessage('☉⌓☉ Error computing your time travel...');
      setMessage(default_message);
    }
    
    setIsLoading(false);
  };

  return (
    <div className="App">
      <header className="site-description">
        <h1>⏱️ Time Travel Meter 🦖</h1>
        { are_updates_available &&
          <strong className="update_note">Updates available: Please refresh the page at your earliest convenience</strong>
        }
        <p>Enter your latest flight details to calculate time traveled</p>
      </header>
      <form onSubmit={handleSubmit}>
        <label className="label" htmlFor="departureDate">Departure Date:</label>
        <input
          type="date"
          name="departureDate"
          placeholder="Departure Date"
          value={formData.departureDate}
          onChange={handleChange}
          required
        />
        <label className="label" htmlFor="departureTime">Departure Time:</label>
        <input
          type="time"
          name="departureTime"
          placeholder="Departure Time"
          value={formData.departureTime}
          onChange={handleChange}
          required
        />
        <label className="label" htmlFor="departureTimezone">Origin Timezone:</label>
        <input
          type="text"
          name="departureTimezone"
          placeholder="Origin Timezone (e.g. UTC+5)"
          value={formData.departureTimezone}
          onChange={handleChange}
          required
        />
        <label className="label" htmlFor="arrivalDate">Arrival Date:</label>
        <input
          type="date"
          name="arrivalDate"
          placeholder="Arrival Date"
          value={formData.arrivalDate}
          onChange={handleChange}
          required
        />
        <label className="label" htmlFor="arrivalTime">Arrival Time:</label>
        <input
          type="time"
          name="arrivalTime"
          placeholder="Arrival Time"
          value={formData.arrivalTime}
          onChange={handleChange}
          required
        />
        <label className="label" htmlFor="arrivalTimezone">Destination Timezone:</label>
        <input
          type="text"
          name="arrivalTimezone"
          placeholder="Destination Timezone (e.g. UTC-7)"
          value={formData.arrivalTimezone}
          onChange={handleChange}
          required
        />
        <label className="label" htmlFor="flightDuration">Flight Duration:</label>
        <input
          type="text"
          name="flightDuration"
          placeholder="Flight Duration (hours)"
          value={formData.flightDuration}
          onChange={handleChange}
          required
        />
        <button type="submit" disabled={isLoading}>
          {isLoading ? <div className="spinner"></div> : 'COMPUTE'}
        </button>
      </form>
      {message && <p className="site-results">{message}</p>}
      {!message && <p>   </p>}
      <br></br>
      <div className="site-details">
        <hr></hr>
        <h2>Coming Soon:</h2>
        <p>✪  Lifetime time travel stats</p>
        <p>✪  Real-time updates as you travel</p>
        <hr></hr>
      </div>
      <footer className="footer">
        <p>Built with <a href="https://buildiful.io">Buildiful</a>. Build your app <a href="https://buildiful.io">here</a>.</p>
      </footer>
    </div>
  );
};

export default App;
